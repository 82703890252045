import request from '@/utils/request'

// 获取餐饮商品列表
export function getProcudtList (data) {
    return request({
        url: '/customer-service/lrCateringGoods/queryCateringGoods',
        method: 'post',
        data
    })
}

// 获取餐饮商户列表
////接口：查询餐饮类别下的商户
//地址：http://${BASE_URL}/customer-service/lrMerchant/queryCateringMerchant?storeId=#{storeId}
export function getMerchantsList (id) {
    return request({
        url: '/customer-service/lrMerchant/queryCateringMerchant?storeId=' + id,
        method: 'get'
    })
}
//  根据id获取餐饮商品详情信息
export function getProductDetail (id) {
    return request({
        url: "/customer-service/lrCateringGoods/queryCateringGoodsDetails?id=" + id,
        method: 'get'
    })
}

//  添加、编辑餐饮商品提交
export function subProduct (data, type) { //type 1 编辑 0 新增 
    return request({
        url: type == 1 ? "/customer-service/lrCateringGoods/updateCateringGoods": "/customer-service/lrCateringGoods/addCateringGoods",
        method: 'post',
        data
    })
}

//  编辑餐饮商品排序
export function subSort (data) {
    return request({
        url: "/goods-service/goods/addGoodsData",
        method: 'post',
        data
    })
}

//  餐饮商品上下架
export function productUpDownShelf (id, status) {
    return request({
        url: "/customer-service/lrCateringGoods/upAndDownCateringGoods?id=" + id + "&status=" + status,
        method: 'post'
    })
}

//  餐饮商品删除
export function productDelete (id) {
    return request({
        url: "/customer-service/lrCateringGoods/delCateringGoods?id=" + id,
        method: 'delete'
    })
}



